import React, { FC, memo } from "react";
import { useSelector } from "react-redux";
import { getProcessingForm } from "../../../stores/selectors";
import { CkIcon } from "../../../CkUI/components";
import "./style.css";

interface IProps {}
const ProcessingFormLoader: FC<IProps> = ({}) => {
  const processingForm = useSelector(getProcessingForm);
  return (
    <div className={["processing-form-loader", ...(processingForm ? ["spinning"] : [])].join(" ")}>
      <CkIcon name="wheel" className="wheel-icon" width="" height="" fill="" />
    </div>
  )
}

export default memo(ProcessingFormLoader);
