import React, { FC } from "react";
import { CkIcon } from "../../../CkUI/components";
import "./style.css";

interface IProps {
  spinning?: boolean;
  mode?: "initial-loader" | "full-page" | "cover-container";
}
const WheelLoader: FC<IProps> = ({ spinning = true, mode = "full-page" }) => {
  return (
    <div
      className={[
        "wheel-loader",
        mode,
        ...(spinning ? ["spinning"] : [""]),
      ].join(" ")}
      style={{ zIndex: 501 }}
    >
      <CkIcon name="wheel" className="wheel-icon" width="" height="" fill="" />
    </div>
  );
};

export default WheelLoader;
