import React, { FC } from "react";
import ReCAPTCHA from "react-google-recaptcha";

interface IProps {
  styles: object;
  setRecaptchaValidation: Function
}
const ReCaptcha : FC<IProps> = ({
  styles,
  setRecaptchaValidation
}) => {
  const key = process.env.REACT_APP_GOOGLE_RECAPTCHA || "";
  if (!!key === false) return null;
  return (
    <ReCAPTCHA
      style={styles}
      sitekey={key}
      onChange={(e) => {
        if (e) {
          setRecaptchaValidation(true);
        }
      }}
      onExpired={() => setRecaptchaValidation(false)}
    />
  );
};

export default ReCaptcha;
