import React, { memo } from "react";
import { Col, Row } from "antd";
import SignOnBenefits1Icon from "../../../assets/SvgIcons/SignOnBenefits1";
import SignOnBenefits2Icon from "../../../assets/SvgIcons/SignOnBenefits2";
import SignOnBenefit3Icon from "../../../assets/SvgIcons/SignOnBenefits3";
import SignOnBenefits4Icon from "../../../assets/SvgIcons/SignOnBenefits4";

interface IProps {}
const benefitList = [
  { benefit: "Seguimiento en todas las etapas", icon: <SignOnBenefits1Icon /> },
  { benefit: "Acceso a formatos digitales", icon: <SignOnBenefits2Icon /> },
  {
    benefit: "Aprobación de cotización en línea",
    icon: <SignOnBenefit3Icon />,
  },
  { benefit: "Registro histórico de servicios", icon: <SignOnBenefits4Icon /> },
];

export const SignOnBenefitsMessage: React.FC<IProps> = ({}: IProps) => {
  return (
    <div
      style={{
        width: "100%",
        backgroundColor: "#EAEFFE",
        padding: "10px 0",
        margin: "10px 0 20px 0",
      }}
      className="sign-on-benefits-message"
    >
      <div className="content-wrapper">
        <Row justify="center">
          <span
            style={{
              fontWeight: "600",
              fontSize: "16px",
              color: "#444444",
              paddingBottom: "10px",
              textAlign: "center",
            }}
          >
            Crear tu cuenta en CarKer te da{" "}
            <span style={{ color: "var(--primary-color)" }}>múltiples beneficios</span>
          </span>
        </Row>
        <Row justify="center">
          <Col>
            {benefitList.map((item) => {
              return (
                <Row>
                  {item.icon}
                  <span
                    style={{
                      fontWeight: "400",
                      fontSize: "16px",
                      paddingLeft: "10px",
                      paddingBottom: "8px",
                    }}
                  >
                    {item.benefit}{" "}
                  </span>
                </Row>
              );
            })}
          </Col>
        </Row>
        <Row justify="center">
          <span style={{ fontSize: "16px", fontWeight: "600" }}>
            ¡Solo estás a un paso!
          </span>
        </Row>
      </div>
    </div>
  );
};

export default memo(SignOnBenefitsMessage);
