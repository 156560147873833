import React from "react";
import { Link } from "react-router-dom";
import { Layout } from "antd";
import { gtmSetId, getImageBaseUrl } from "../../../utilities";
import { CkButton } from "../../../CkUI";
import "./styles.css";

const Logo = getImageBaseUrl("logos/logo_negative.svg");
const Logo2 = getImageBaseUrl("logos/logo_negative.svg");

interface IProps {}
const { Footer } = Layout;

const scrollToTop = () => {
  window.scrollTo(0, 0);
};

export const AppFooter: React.FC<IProps> = () => (
  <Footer style={{ zIndex: 1 }}>
    <section className="footer">
      <div className="mobile-center">
        <figure>
          <Link
            to={"/"}
            aria-label={"Ir a la página principal"}
            onClick={scrollToTop}
          >
            <picture className="footer-logo">
              <source
                media="(min-width:1200px)"
                srcSet={Logo2}
                width={240}
                height={88}
              />
              <img
                src={Logo}
                alt="CarKer logo"
                width={110}
                height={41}
                loading="lazy"
              />
            </picture>
          </Link>

          <figcaption>
            <p>Transformando el mundo del cuidado automotriz</p>
          </figcaption>
        </figure>
      </div>
      <div className="mobile-center">
        <ul className="two-columns">
          <li key={8} className="li-margin">
            <Link
              id="FOOTR005"
              to={{
                pathname: "/blog/1",
              }}
              rel="noopener noreferrer"
              onClick={(e) => {
                gtmSetId(e.currentTarget);
                scrollToTop();
              }}
              className="footer-text"
            >
              Blog
            </Link>
          </li>
          <li key={14} className="li-margin">
            <Link
              id="FOOTR007"
              to={{ pathname: "/quienes-somos" }}
              onClick={(e) => {
                gtmSetId(e.currentTarget);
                scrollToTop();
              }}
              className="footer-text"
            >
              ¿Quiénes somos?
            </Link>
          </li>
          <li key={4} className="li-margin">
            <Link
              id="FOOTR006"
              to={{
                pathname: "/talleres-afiliados",
              }}
              onClick={(e) => {
                gtmSetId(e.currentTarget);
                scrollToTop();
              }}
              className="footer-text"
            >
              Talleres afiliados
            </Link>
          </li>
          <li key={3} className="li-margin">
            <Link
              id="FOOTR008"
              to={{
                pathname: "/preguntas-frecuentes",
                hash: "frequentQuestionsContain",
              }}
              onClick={(e) => {
                gtmSetId(e.currentTarget);
                scrollToTop();
              }}
              className="footer-text"
            >
              Preguntas frecuentes
            </Link>
          </li>
          <li key={7} className="li-margin">
            <Link
              id="FOOTR009"
              to={{ pathname: "/contacto" }}
              onClick={(e) => {
                gtmSetId(e.currentTarget);
                scrollToTop();
              }}
              className="footer-text"
            >
              Contáctanos
            </Link>
          </li>
          <li key={12} className="li-margin">
            <a
              id="FOOTR010"
              href="https://corporate.exxonmobil.com/Global-legal-pages/privacy-policy_mexico-spanish_marketing"
              className="footer-text"
              rel="noopener noreferrer"
              target="_blank"
              onClick={(e) => {
                gtmSetId(e.currentTarget);
              }}
            >
              Aviso de privacidad
            </a>
          </li>
          <li key={13} className="li-margin">
            <a
              href="/terminosycondiciones"
              className="footer-text"
              rel="noopener noreferrer"
              onClick={(e) => {}}
            >
              Términos y condiciones
            </a>
          </li>
        </ul>
        <div
          className="div-row mobile-block contact-data"
          style={{
            marginTop: 25,
            marginBottom: 15,
          }}
        >
          <CkButton
            id="FOOTR011"
            href="tel:5522820800"
            rel="noopener noreferrer"
            target="_blank"
            color="white"
            type="text"
            triggerGTMOnClick
            block={false}
          >
            55 2282 0800
          </CkButton>
          |
          <CkButton
            id="FOOTR012"
            href="mailto:soporte@carker.com.mx"
            rel="noopener noreferrer"
            target="_blank"
            onClick={(e) => {
              gtmSetId(e.currentTarget);
            }}
            block={false}
            color="white"
            type="text"
          >
            soporte@carker.com.mx
          </CkButton>
        </div>
      </div>
    </section>
    <section className="second-footer">
      <p className="div-row">© 2022 CarKer™. Todos los derechos reservados.</p>
      <div className="div-row mobile-block-reverse">
        <CkButton
          data-gtm-id="FOOTR011"
          href="tel:5522820800"
          rel="noopener noreferrer"
          target="_blank"
          icon={"phone"}
          color="white"
          triggerGTMOnClick
          type="text"
          block={false}
        >
          55 2282 0800
        </CkButton>
        <CkButton
          data-gtm-id="FOOTR012"
          href="mailto:soporte@carker.com.mx"
          rel="noopener noreferrer"
          target="_blank"
          color="white"
          type="link"
          triggerGTMOnClick
          icon="envelop"
          block={false}
        >
          soporte@carker.com.mx
        </CkButton>
      </div>
    </section>
  </Footer>
);

export default AppFooter;
