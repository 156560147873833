import { FC, memo, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  setProcessingForm
} from "../../../stores/Slice/PublicCatalogSlice";

interface IProps {}
const ProcessingFormLoaderTriggerer: FC<IProps> = ({}) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setProcessingForm(true));
    return () => {
      dispatch(setProcessingForm(false));
    }
  }, []);

  
  return null;
}

export default memo(ProcessingFormLoaderTriggerer);
