import React, { FC, memo } from "react";
import { Modal } from "antd";
import { useSelector, useDispatch } from "react-redux";

import { CKCloseIcon } from "../../../assets/SvgIcons";
import { getPromotionalModal, hidePromotionTermsModal } from "../../../stores";

import "./styles.css";

interface IProps {}

const PromotionsTermsModal: FC<IProps> = () => {
  const dispatch = useDispatch();
  const promotionalModal = useSelector(getPromotionalModal);

  const onCancel = () => {
    dispatch(hidePromotionTermsModal());
  };

  const formatTerms = (terms: string[]) => {
    if (!terms[0]) {
      return [""];
    }

    const termsArray = terms[0].split("|");

    return termsArray;
  };

  return (
    <Modal
      open={promotionalModal.show}
      onCancel={onCancel}
      footer={null}
      className="promotion-terms-modal"
      closeIcon={<CKCloseIcon />}
      centered
      zIndex={10000}
    >
      <h1>Términos y Condiciones</h1>
      {promotionalModal.title && <h1>{promotionalModal.title.trim()}</h1>}
      <ul>
        {formatTerms(promotionalModal?.terms).map((term, index) => (
          <li key={`term-${index}`}>{term}</li>
        ))}
      </ul>
    </Modal>
  );
};
export default memo(PromotionsTermsModal);
